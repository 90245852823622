
import { myIF, myRC, myART } from "print/data.js";
import { myEnt, myPied } from "print/data.js";
var dataimg;
  
        
      function mmTopx(mm) {
        var div = document.createElement("div");
        div.style.display = "block";
        div.style.height = "100mm";
        document.body.appendChild(div);
        var convert = (div.offsetHeight * mm) / 100;
        div.parentNode.removeChild(div);
        return convert;
      }
export function marges2(doc,store)  {
  let doc_marges={}
  doc_marges.margin_top= store.state.options[0].doc_top;
  doc_marges.margin_left= store.state.options[0].doc_left;
  doc_marges.margin_right= store.state.options[0].doc_right;
  doc_marges.margin_bottom= store.state.options[0].doc_bottom;
let coef =
        doc.internal.pageSize.width / mmTopx(doc.internal.pageSize.width);
        doc_marges.l_w=0
        doc_marges.l_h=0
      if (myEnt) {
        dataimg = myEnt;
        const imgProps = doc.getImageProperties(dataimg);
        doc_marges.l_w = imgProps.width * coef;
        doc_marges.l_h = imgProps.height * coef;
      }
      doc_marges.p_w=0
      doc_marges.p_h =0
      if (myPied) {
        dataimg = myPied;
        const imgProps = doc.getImageProperties(dataimg);
        doc_marges.p_w = imgProps.width * coef;
        doc_marges.p_h = imgProps.height * coef;
      }

  return doc_marges
}          
export const addHeaders = (doc,store,cmpt,info,company) => {
    const pageCount = doc.internal.getNumberOfPages();
let marges=marges2(doc,store)
  
   let  nif = myIF;
   let  rc = myRC;
   let  art = myART;
  

    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
  
      if (myEnt) {
        dataimg = myEnt;
        doc.addImage(
          dataimg,
          "png",
          (doc.internal.pageSize.width - marges.l_w) / 2,
          marges.margin_top,
          marges.l_w,
          marges.l_h
        );
     
      }

      if (info) {
        let columnStyles
       let columns = [];
       columns.push({ dataKey: "rc_lib" });
        columns.push({ dataKey: "rc" });
        columns.push({ dataKey: "if_lib" });
        columns.push({ dataKey: "if" });
        columns.push({ dataKey: "art_lib" });
        columns.push({ dataKey: "art" });
        if (cmpt) {
          columns.push({ dataKey: "cmpt_lib" });
          columns.push({ dataKey: "cmpt" });
         columnStyles= {
            0: { cellWidth: 11,fontStyle:"bold",halign: 'left' },
            1: { cellWidth: 20,halign: 'left' },
            2: { cellWidth: 10,fontStyle:"bold",halign: 'right' },
            3: { cellWidth: 25,halign: 'left' },
            4: { cellWidth: 15,fontStyle:"bold",halign: 'right' },
            5: { cellWidth: 20,halign: 'left' },
            6: { cellWidth: 20,fontStyle:"bold",halign: 'right' },
            7: { cellWidth: "auto",halign: 'right' },
          }
        } else {
          columnStyles= {
            0: { cellWidth: 11,fontStyle:"bold",halign: 'left' },
            1: { cellWidth: 60,halign: 'left' },
            2: { cellWidth: 10,fontStyle:"bold",halign: 'right' },
            3: { cellWidth: 45,halign: 'left' },          
            4: { cellWidth: "auto",fontStyle:"bold",halign: 'right' },
            5: { cellWidth: 20,halign: 'right' },
          }
        }   
        let list = [];
        if (cmpt)
        list.push({rc_lib:"RC N° ", rc: rc,if_lib:"IF N° ", if: nif ,
        art_lib:"ART N° ", art: art,cmpt_lib:"Compte ", cmpt: cmpt });
        else list.push({rc_lib:"RC N° ", rc: rc,if_lib:"IF N° ", if: nif ,
        art_lib:"ART N° ", art: art });
       
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.autoTable({
          startY: marges.margin_top + marges.l_h-5 ,
          margin: { left: marges.margin_left,top:marges.margin_top + marges.l_h + 5 },          
          columns: columns,
          columnStyles: columnStyles,
          body: list,
          theme: "plain",         
          styles: {
            valign: "middle",
            fontSize: 8,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            cellPadding: 1,
          },
          tableWidth: "auto",
        });

      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0);
      doc.line(
        marges.margin_left,
        marges.margin_top + marges.l_h ,
        doc.internal.pageSize.width - marges.margin_right,
        marges.margin_top + marges.l_h 
      );
      doc.text(company,marges.margin_left,marges.margin_top + marges.l_h+5)
      }
    
 
    }
  };
export  const addFooters = (doc,store) => {
    var pageCount = doc.internal.getNumberOfPages();
   
    let marges=marges2(doc,store)
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      
      if (myPied) {
        dataimg = myPied;
        
        doc.addImage(
          dataimg,
          "png",
          (doc.internal.pageSize.width - marges.p_w) / 2,
          doc.internal.pageSize.height - marges.margin_bottom - marges.p_h,
          marges.p_w,
          marges.p_h
        );
      }
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - marges.margin_bottom,
        {
          align: "center",
        }
      );
    }
  };
  export  const addFootersFactList = (doc,store,pageStart,pageCount) => {
   
    let marges=marges2(doc,store)
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i*pageStart);
      
      if (myPied) {
        dataimg = myPied;      
        doc.line(
          (doc.internal.pageSize.width - marges.p_w) / 2,
          doc.internal.pageSize.height - marges.margin_bottom - marges.p_h - 1,
          (doc.internal.pageSize.width - marges.p_w) / 2 + marges.p_w,
          doc.internal.pageSize.height - marges.margin_bottom - marges.p_h - 1
        );
        doc.addImage(
          dataimg,
          "png",
          (doc.internal.pageSize.width - marges.p_w) / 2,
          doc.internal.pageSize.height - marges.margin_bottom - marges.p_h,
          marges.p_w,
          marges.p_h
        );
      }
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - marges.margin_bottom,
        {
          align: "center",
        }
      );
    }
  };

  